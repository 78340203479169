$media-md: 768px;

body.error-page {
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  font-family: system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif;
}

body.error-page {
  a, a:hover {
    color: #0d6efd;
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
  }

  .container {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: center;

    .row {
      width: 100%;
      box-sizing: border-box;

      .col-lg-6 {
        width: 100%;
        box-sizing: border-box;

        .mt-4 {
          width: 90%;
          box-sizing: border-box;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          margin: 50px auto 0 auto;

          @media screen and (max-width: $media-md) {
            margin: 100px auto 0 auto;
          }

          .text-arrow-icon {
            display: flex;
            align-items: center;
            justify-content: center;

            svg {
              margin-right: 12px;

              @media screen and (max-width: $media-md) {
                margin-right: 6px;
              }
            }
          }

          .lead, .text-arrow-icon {
            font-size: 24px;

            @media screen and (max-width: $media-md) {
              font-size: 16px;
            }
          }

          img {
            width: 100%;
            max-width: 498px;
            height: auto;
            object-fit: contain;

            @media screen and (max-width: $media-md) {
              width: 70%;
            }
          }
        }
      }
    }
  }
}

.sentry-error-embed-wrapper {
  z-index: 9999;
}
